import styled from "styled-components";

export const ForgotPasswordStyle = styled.div`
    .cardheader{
        font-size:2.4vw;
        color:#2A799B;
    }
    .cardtitle{
        font-size:1.6vw;
        color:#2A799B;
    }

`
  