import styled from 'styled-components';
import { Container } from 'react-bootstrap';

export const AuthPageLogo = styled.img`
	height: 14rem;
`;

export const AppColorBackground = styled.div`
	background-color: var(--app-color1);
	
.nav-borders{
    border: 1px solid #9f9cb4;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    border-bottom: none;
}

.pointer{
	cursor:pointer;
}

	
`;

export const AuthenticationFormContainer = styled(Container)`
	
	min-height: 341px;
    border: 1px solid var(--app-color-1);
    background-color: #dedce3;
	border: none;
	.nav-item>button {
		border-top-right-radius: 2rem;
		border-top-left-radius: 2rem;
		background-color: var(--app-color2);
		border-bottom: none;
		font-size: 1.75rem;
		font-weight: bold;
		color:#66B4D6;
		font-style:italic;
	}
	.nav-item>button.active {
		background-color: #dedce3;
		color:#66B4D6;
		
	}
	.tab-content {
		padding: 0 2rem 1rem 2rem;
	}
	li.nav-item{
		border: 1px solid #9f9cb4;
    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;
    border-bottom: none;
		}
	ul.nav{
		background-color:#2A799B;
	}

`;


export const AuthenticationFormContainerForReset = styled(Container)`
	
	min-height: 341px;
    border: 1px solid var(--app-color-1);
    background-color: #dedce3;
	border: none;
	`

export const LoginButton = styled.button`
    padding: 0.1rem 4rem;
    background-color: var(--app-color2);
    border-color: var(--app-color-1);
    border-width: 0.2rem;
    color: var(--app-color-1) !important;
    font-weight: bold;
		font-size: 1.75rem;
`;

const commonFormControl = `
    background: none;
    border: none;
    padding: 0rem 1rem;
    font-weight: bold;
    appearance: none;
		outline:0;
		color: var(--app-color1);
`;

export const Select = styled.select`
`;

export const Input = styled.input`
	appearance: none;
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" fill="rgb(74, 77, 119)"/></svg>');

	background-repeat: no-repeat;
	background-position: right center;
	background-size: 16px;
	width: 100%;
	right: 10px;
	${commonFormControl}
`;

export const TextArea = styled.textarea`
	appearance: none;
	background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" fill="rgb(74, 77, 119)"/></svg>');

	background-repeat: no-repeat;
	background-position: right center;
	background-size: 16px;
	width: 100%;
	right: 10px;
	${commonFormControl}
`;

export const DashedLine = styled.div`
  border-top: .2rem dashed #2A799B;	
  position: relative;
	margin-block: 1rem;
  color: #2A799B;
  &::after {
    content: '›';
		font-weight: bold;
		top: -0.2rem;
    position: absolute;
    right: -10px;
    transform: translateY(-50%);
    font-size: 1.5rem;
    color: #2A799B;
  }
`

