import { Card,Image } from "react-bootstrap";
import { useAppContextWrapper } from "../../../shared/contexts/AppContextWrapper";
import { useAppSelector } from "../../../store";

interface IProps {
	lessonId: string;
	lessonName: string;
	onSelect?: (value: string) => void;
}

export const UICard = (props: IProps) => {
const lessons=useAppSelector(state=>state.lessons)
	const [lesson_name, lesson_index]=props?.lessonName?.split(" ")

	return (
		<Card  className="w-100 bg-light rounded-4 border-0" style={{height:250}} onClick={() => props.onSelect?.(props.lessonId)}>

			<Card.Header 
			className={props?.lessonId!==lessons?.selectedLesson?.lessonId?"invisible":"visible"}
			style={{
				borderBottomWidth:0,
				backgroundColor:"transparent",
				position:"absolute",
				right:0
			}}>	
			<Image style={{width:18, height:"auto"}} src={"./icons/checked.png"}/>
			</Card.Header>
			
			<Card.Body
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
				}}
			>

				<Card.Text className="text-center fs-4 ">
					{lesson_name?.toLowerCase()}
					<br />
					{lesson_index}
				</Card.Text>
			</Card.Body>
		</Card>
	);
};
