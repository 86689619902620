import TextInput from "../../shared/components/form/TextInput";
import { LoginButton } from "../../styled-components/common";
import { FormikWithWrapper } from "../../shared/contexts/FormikWrapper";
import { LoginPayload, ResetPasswordPayload } from "@vokab/shared/src/types";
import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAutHook } from "../../shared/hooks/useAuthHook";
import { FormStyle } from "./style";
import Form from 'react-bootstrap/Form';
import { FormikProps } from "formik";
import { resetPasswordSchema } from "@vokab/shared/src/schemas";

const initialValues = {
  password: '',
  confirmPassword: ''
};

export default function ResetPassword() {
  const [showLoginForm, setShowLoginForm] = useState(false);
  const navigate = useNavigate();
  const { resetPassword } = useAutHook();
  const [searchParams] = useSearchParams();
  const userId = searchParams.get('userId')
	const verification_hash = searchParams.get('verification_hash')

  const submit = async (values: ResetPasswordPayload) => {
    if (userId) {
     try {
			await resetPassword({
        userId: userId ?? '',
        password: values.password,
        confirmPassword: values.confirmPassword,
				verification_hash:verification_hash??''
      })
		 } catch (error) {
			
		 }
    }
  };

  return (
    <>
      <FormStyle>
        <div>
          <h3 className="text-center mt-3 form-title"><b>reset your password</b></h3>
          <FormikWithWrapper
            enableReinitialize
            validateOnMount
            initialValues={initialValues}
            validationSchema={resetPasswordSchema}
            onSubmit={submit}
          >
            {
              (props: FormikProps<LoginPayload>) => (
                <Form className="pt-3" onSubmit={props.handleSubmit} noValidate>
                  <TextInput name="password" className="mb-1" type="password" placeholder="password" />
                  <TextInput name="confirmPassword" className="mb-1" type="password" placeholder="confirm password" />
                  <div className="d-flex align-items-center justify-content-between flex-column">
                    <div className="text-center ">
                      <LoginButton type="submit" className="btn btn-primary form-button mx-auto m-3 rounded-5 ">reset</LoginButton>
                    </div>
                  </div>
                </Form>
              )
            }
          </FormikWithWrapper>
        </div>
      </FormStyle>
    </>
  );
}