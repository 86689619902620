import React from 'react'
import { Image,Table } from 'react-bootstrap';
import styled from 'styled-components';

const CardContainer=styled.div`
width:100%;
class:text-center;
borderRadius:20;
box-shadow: 0px 0px 16px 0px var(--app-color1);
`

export default function ProfileCard() {
	return (
    <CardContainer
		
		className='text-center rounded'
    >
      <div
        className="bg-light p-4 "
        style={{
          backgroundColor: "#eee",
          borderRadius: 24,
          minHeight: 300,
        }}
      >
        <Image
          fluid
          style={{ width: 128, height: "auto" }}
          src={"./Asserts/profile.png"}
          className="text-venter mb-4 rounded-circle"
        />
        <Table>
          <tbody>
            <tr>
              <td
                style={{ backgroundColor: "transparent" }}
                className="fw-bold"
              >
                நிறுவனர்{" "}
              </td>
              <td
                style={{ backgroundColor: "transparent" }}
                className="fw-bold"
              >
                Founder
              </td>
            </tr>
            <tr>
              <td
                style={{ backgroundColor: "transparent" }}
                className="fw-bold"
              >
                கபிலன்
                <br /> பிரசன்னா{" "}
              </td>
              <td
                style={{ backgroundColor: "transparent" }}
                className="fw-bold"
              >
                Kabilan
                <br /> Prasanna
              </td>
            </tr>
           
          </tbody>
        </Table>
             <div className="row">
             <td className="text-center  ">
								<p className='link-secondary'></p><b>email us:</b> <a href='email:info@linguistics.world' className='link-secondary'>info@linguistics.world</a>
              </td>
             </div>
     
      </div>

    </CardContainer>
  );
}
