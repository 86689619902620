/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useMemo } from "react";
import { Layout } from "../home/Layout";
import { useAppHook } from "../../shared/hooks/useAppHook";
import { UICard } from "./Card";
import { Col, Dropdown, Row, Breadcrumb, ListGroup } from "react-bootstrap";
import styled from "styled-components";
import { ILesson, IProfLevel } from "@vokab/shared/src/types";
import LanguageSwapButton from "../../shared/components/LanguageSwapButton";
import React from "react";
import { store, useAppDispatch, useAppSelector } from "../../store";
import { proLevelActions } from "../../store/slices/levelSlice";
import { useNavigate } from "react-router-dom";
import { lessonActions } from "../../store/slices/lessonSlice";
import { HomeStyle } from "../home";
import { instance } from "../../api";
import AppSpinner from "../../shared/components/AppSpinner";
import api from "../../store/services/api";
const Pointer = styled.div`
	cursor: pointer;
`;

export interface IState {
	profId: string;
	targetLanguage: string;
	sourceLanguage: string;
	customCategoryId: string;
	selectedCategory: any;
}

export const FlashcardsHome = React.memo(() => {
	const levels = useAppSelector((state) => state.profLevels);
	// const lessons = useAppSelector((state) => state.lessons);
	const { data: lessons,isLoading:lessonApiLoading } = api.useGetAllLessonsQuery();
	const languages = useAppSelector((state) => state.languages);
	const { data, status } = api.useGetAllProficiencyLevelsQuery();

	useEffect(() => {
		if (status === "fulfilled") {
			if (data?.result.data?.[0] && !levels?.selectedProfLevel?.profLevelId)
				dispatch(proLevelActions.setSelectedProfLevel(data?.result.data?.[0]));
			if (
				data?.result.data?.[0]?.categories?.[0] &&
				!levels?.selectedCategory?.categoryId
			)
				dispatch(
					proLevelActions.setSelectedCategory(
						data?.result.data?.[0]?.categories?.[0]
					)
				);
		}
	}, [status, data]);

	const dispatch = useAppDispatch();

	const navigate = useNavigate();
	const [fetch,{data:targetCate}]=api.useGetAllVocabulariesMutation()
	
	useEffect(() => {
		fetch({
			word: levels?.selectedCategory?.categoryName,
			targetLanguageCode: languages?.targetLanguage?.languageCode,
		})
	}, [levels?.selectedCategory, languages?.targetLanguage, instance]);

	const handleLessonChange = (props: ILesson) => {
		const { sourceLanguage, targetLanguage } = languages;
		const { selectedProfLevel, selectedCategory } = levels;
		dispatch(lessonActions.setSelectedLesson(props));

		navigate(
			`/flashcards/categories?categoryId=${selectedCategory?.categoryId}&categoryName=${selectedCategory?.categoryName}&lessonId=${props?.lessonId}&sourceLanguage=${sourceLanguage?.languageName}&targetLanguage=${targetLanguage?.languageName}&profLevel=${selectedProfLevel?.profLevel}&lessonName=${props?.lessonName}`
		);
	};

	const handlePrefLevelChange = (level: IProfLevel) => {
		dispatch(proLevelActions.setSelectedProfLevel(level));
		dispatch(
			lessonActions.setDefaultSelectedLesson({
				lessonId: "0aee8827-7248-4852-848f-0eb539f3244a",
				lessonName: "Lesson 1",
				items: [],
				displayFlag: true,
				displayOrder: 0,
			})
		);
	};
	const handleCategoryChange = (data: any) => {
		dispatch(proLevelActions.setSelectedCategory(data));
		store.dispatch({
			type: "lessons/setDefaultSelectedLesson",
			payload: {
				lessonId: "0aee8827-7248-4852-848f-0eb539f3244a",
				lessonName: "Lesson 1",
				items: [],
				displayFlag: true,
				displayOrder: 0,
			},
		});
	};




	const targetVocname=useMemo(() => {
		const targetCategory=targetCate?.result?.data?.[0]?.voc_lang?.find(
			(v: any) => v.languageId === languages?.targetLanguage?.languageId
		)
		if(targetCategory){
			return ` - ${targetCategory.word}`
		}

		return ""
	}, [levels?.selectedTargetVocabulary,targetCate]);

	return (
		<div>
			<Layout>
				<HomeStyle>
					<Row>
						<Col md={3} xs={12}>
							{levels?.isLoading ? (
								<AppSpinner />
							) : (
								<React.Fragment>
									<Dropdown
										style={{
											width: "133%",
										}}
										className="mt-4 show"
									>
										<Dropdown.Toggle
											className="w-75 text-start   py-2 bg-light dropdown-toggle show "
											variant="light"
											id="dropdown-basic"
											aria-expanded="true"
										>
											<span className=" cmn-font">
												{levels?.selectedProfLevel?.profLevel?.toLowerCase()}
											</span>
										</Dropdown.Toggle>

										<Dropdown.Menu className="w-75">
											{data?.result?.data?.map((profLevel) => (
												<Pointer>
													<Dropdown.Item
														onClick={() => handlePrefLevelChange(profLevel)}
														className={
															levels?.selectedProfLevel?.profLevel ===
															profLevel?.profLevel
																? "text-primary  cmn-font "
																: " cmn-font text-dark"
														}
														href="#/action-1"
													>
														{profLevel?.profLevel?.toLowerCase()}
													</Dropdown.Item>
												</Pointer>
											))}
										</Dropdown.Menu>
									</Dropdown>
									<ListGroup
										className="mt-4 rounded-1 "
										variant="flush"
										defaultActiveKey={"#cates"}
									>
										<ListGroup.Item
											className="bg-light text-dark border"
											href="#cates"
										>
											<div>categories</div>
										</ListGroup.Item>
									</ListGroup>

									<ListGroup className="mt-2" variant="flush">
										{levels?.selectedProfLevel?.categories?.map((category) => (
											<ListGroup.Item
												className={`${
													levels?.selectedCategory?.categoryId ===
													category?.categoryId
														? "text-primary"
														: "bg-transparent"
												}`}
											>
												<Pointer onClick={() => handleCategoryChange(category)}>
													{category?.categoryName?.toLowerCase()}
												</Pointer>
											</ListGroup.Item>
										))}
									</ListGroup>
								</React.Fragment>
							)}
						</Col>
						<Col md={9}>
							<Row className="mt-4 ms-2">
								<Col>
									<Breadcrumb>
										<Breadcrumb.Item href="#" active>
											{levels?.selectedProfLevel?.profLevel?.toLowerCase()}
										</Breadcrumb.Item>
										<Breadcrumb.Item href="#" active>
											{levels?.selectedCategory?.categoryName?.toLowerCase()}
											{targetVocname}
											
										</Breadcrumb.Item>
									</Breadcrumb>
								</Col>
								<Col lg={6} xs={12}>
									<LanguageSwapButton />
								</Col>
							</Row>

							{
								lessonApiLoading 
								? <AppSpinner /> 
								: 

								<Row
								className="mt-2 container-fluid scrollbar scrollbar-primary gy-4  d-flex flex-wrap align-items-center justify-content-between"
								style={{
									height: "calc(100vh - 290px)",
								}}
							>
								{lessons?.result?.data.result?.map((lesson, i) => {
									return (
										<Col md={3} xs={12}>
											<Pointer>
												<UICard
													onSelect={(id) => handleLessonChange(lesson)}
													lessonId={lesson?.lessonId}
													lessonName={lesson?.lessonName}
												/>
											</Pointer>
										</Col>
									);
								})}
							</Row>
							}
						</Col>
					</Row>
				</HomeStyle>
			</Layout>
		</div>
	);
});
