import { Container, Row, Col } from "react-bootstrap";
import { LoginButton } from "../../styled-components/common";
import TextInput from "../../shared/components/form/TextInput";
import { FormikWithWrapper } from "../../shared/contexts/FormikWrapper";
import { Form, FormikProps } from 'formik';
import { SignupPayload } from "@vokab/shared/src/types";
import { userRegistration } from "@vokab/shared/src/schemas";
import { useAutHook } from "../../shared/hooks/useAuthHook";
import { FormStyle } from "./style";

const initialValues = {
	firstName: '',
	lastName: '',
	email: '',
	password: '',
	confirmPassword: ''
};


export default function Signup() {
	const { signup } = useAutHook();
	const register = (values: SignupPayload) => {
		signup(values);
	};

	return (
		<>
			<FormStyle>
			<h3 className="text-center mt-3 form-title"><b>create a new account</b></h3>
			<FormikWithWrapper
				enableReinitialize
				validateOnMount
				initialValues={initialValues}
				validationSchema={userRegistration}
				onSubmit={register}
			>
				{
					(props: FormikProps<SignupPayload>) => (
						<Form onSubmit={props.handleSubmit} noValidate>
							<Container>
								<Row >
									<Col className="p-0">
										<TextInput name="firstName" className="mb-1" type="text" placeholder="first name" />
									</Col>
									<Col className="p-0">
										<TextInput name="lastName" className="mb-1" type="text" placeholder="last name" />
									</Col>
								</Row>
							</Container>
							<TextInput name="email" className="mb-1" type="email" placeholder="email@address.com" />
							<TextInput name="password" className="mb-1" type="password" placeholder="password" />
							<TextInput name="confirmPassword" className="mb-1" type="password" placeholder="confirm password" />
							<div className="text-center">
								<LoginButton type="submit" className="btn btn-primary form-button mx-auto m-3 rounded-5">sign up</LoginButton>
							</div>
						</Form>
					)
				}
			</FormikWithWrapper>
			</FormStyle>
		</>
	);
}