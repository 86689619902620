import './App.scss';
import { AppContextWrapper } from './shared/contexts/AppContextWrapper';
import { NotificationContextWrapper } from './shared/contexts/NotificationContextWrapper';
import { BrowserRouter } from 'react-router-dom';
import { UserContextWrapper } from './shared/contexts/UserContextWrapper';
import RootRouter from './routes/routes';
import { Provider } from 'react-redux';
import { store } from './store';

function App() {
	return (
		<Provider store={store} >


		<NotificationContextWrapper>
			<AppContextWrapper>
				<UserContextWrapper>
					<BrowserRouter>
						<RootRouter />
					</BrowserRouter>
				</UserContextWrapper>
			</AppContextWrapper>
		</NotificationContextWrapper>

		</Provider>
	);
}

export default App;
