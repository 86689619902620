import Modal from 'react-bootstrap/Modal';
import AddMyCategoryDetailsForm from './AddMyCategoryDetailsForm';
import { ISelected } from './CategoryListTable';
import { useUserContextWrapper } from '../../shared/contexts/UserContextWrapper';
import { LoginButton } from '../../styled-components/common';
import { useNavigate } from 'react-router-dom';

interface IProps {
  show: boolean;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
  state: ISelected;
}

const AddMyCategoryDialog = ({ show, setShow, state }: IProps) => {
  const { isLoggedIn } = useUserContextWrapper();
  const navigate = useNavigate()

  return (
    <Modal
      size='sm'
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => setShow(!show)}
      show={show}
      animation={false}
    >
      {isLoggedIn ? (<div> <Modal.Header closeButton>
        <h5><b>add word to categories</b></h5>
      </Modal.Header>
        <AddMyCategoryDetailsForm setShow={setShow} show={show} selected={state} />

      </div>) :
        <div style={{
          padding: '10px'
        }}>
          <Modal.Header closeButton>
            <h5><b>add word to categories</b></h5>
          </Modal.Header>
          <div>
            you must login to add words to your categories
          </div>
          <div className="text-center pt-3">
            <LoginButton onClick={() => navigate('/auth/login')} className="btn btn-primary form-button mx-auto m-3 rounded-5 ">login</LoginButton>
          </div>
        </div>
      }
    </Modal>
  );
}

export default AddMyCategoryDialog;