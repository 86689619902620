import * as Yup from 'yup';
import {
	checkStringRequiredLength,
} from '../validation/validators';

export const createMyCategorySchema = Yup.object({
	categoryName: checkStringRequiredLength()
});

export const addMyCategoryDetailsSchema = Yup.object({
	customCategoryId: checkStringRequiredLength()
});