import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Container, Row, Col } from "react-bootstrap";
import { DashedLine } from "../../styled-components/common";
import { Layout } from "../home/Layout";
import RegistrationButton from "../nationalVokab/components/RegistrationButton";

export const EventsHome = () => {
	return (
		<Layout>
			<Container>
			
			<Row>
			<div className="mt-lg-4"></div>
			<div className="mt-lg-4"></div>
			<div className="mt-lg-4"></div>
				<div className="mt-4"></div>
						<Col  >
									<p className="h1 fw-bolder ">
										<span className="tm-text fw-bolder">	ஆங்கிலப் பேச்சுப்பயிற்சி பட்டறை</span>
											<br />
											spoken english workshop
									</p>

									<DashedLine className="col-lg-8" />
								</Col>
								<p>
									<span className="fw-bold">pechu’s</span> virtual english workshops give Tamil speakers
									of India the opportunity to practice and improve their
									day-to-day speech.
								</p>
								<p className="fw-bold">What to Expect:</p>
								<p>
										<FontAwesomeIcon icon={faArrowRight} className="me-4" />
										<span className="fw-bold pe-2">weekly sessions:</span>
									 practice and learn to speak English with native speakers.
								</p>

								<p>
										<FontAwesomeIcon icon={faArrowRight} className="me-4" />
										<span className="fw-bold pe-2"> open to all:</span>
									no matter your language proficiency, anyone is welcome to join
									and learn!.
								</p>
								<div className="d-flex justify-content-center mt-4">
								<RegistrationButton />
								</div>
					
					</Row>
			</Container>
		</Layout>
	);
};
