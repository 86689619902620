import { IUpdateProfile, LoginPayload, ResetPasswordPayload, SignupPayload } from "@vokab/shared/src/types";
import { apiCallWithLoading } from "./";

export const userSignup = (payload: SignupPayload) => apiCallWithLoading<string>({
	method: 'POST',
	url: 'auth/signup',
	data: payload
});

export const userLogin = (payload: LoginPayload) => apiCallWithLoading<string>({
	method: 'POST', url: 'auth/login', data: payload
});

export const userResetPassword = (payload: ResetPasswordPayload) => apiCallWithLoading<string>({
	method: 'POST',
	url: 'auth/password/reset/request/complete',
	data: payload
});
export const userProfileUpdate = (payload: IUpdateProfile) => apiCallWithLoading<string>({
	method: 'POST',
	url: 'auth/updateProfile',
	data: payload
});