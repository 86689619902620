/* eslint-disable eqeqeq */
import * as T from "@vokab/shared/src/types";
import {
	allLanguages,
	categoriesByIdRequest,
	getLessonsByCategoryId,
} from "../../api/common";
import {
	IAppContextState,
	useAppContextWrapper,
} from "../contexts/AppContextWrapper";
import { useNotificationContextWrapper } from "../contexts/NotificationContextWrapper";
import { sendForgotPassword } from "../../api/mailService";
import { store } from "../../store";
import { instance } from "../../api";

export const useAppHook = () => {
	const { appStateSetter, ...state } = useAppContextWrapper();
	const { notifyError, notifySuccess } = useNotificationContextWrapper();



	const getAllLanguages = async () => {
		const [res] = await allLanguages();
		store.dispatch({type:"languages/setLanguages",payload:res?.result?.data});
	};

	const getAllProfLevels = async () => {
		store.dispatch({type:"profLevels/setLoading",payload:true})
		const result=await instance().get("common/prof-levels").finally(()=>store.dispatch({type:"profLevels/setLoading",payload:false}))
		store.dispatch({type:"profLevels/setProfLevels",payload:result?.data?.result?.data});
	};


	const getCategoriesById = async (filterObject: T.CategoriesById) => {
		const [res] = await categoriesByIdRequest(filterObject);
		store.dispatch({type:"lessons/setSelectedLessonItems",payload:res?.result?.data?.result});
	};










	const sendForgotPasswordLink = async (payload: T.IForgotDetailsPayload) => {
		const [result, error] = await sendForgotPassword(payload);
		if(error?.status != "200"){
			notifyError({ message: result?.message });
		}

		if (error) notifyError({ message: error.message });
		else {
			notifySuccess("reset password mail send successfully");
		}
	};

	const fetchLessonsByCategoryId = async (payload: string) => {
		const [res] = await getLessonsByCategoryId(payload);
	
		store.dispatch({type:"lessons/setLessons",payload:res?.result?.data?.result});

		
	};
	return {
		getAllLanguages,
		getAllProfLevels,
		getCategoriesById,
		sendForgotPasswordLink,
		fetchLessonsByCategoryId,
		setPreferences: (payload: Partial<IAppContextState>) =>
			{
				console.log("setPreferences-payload", payload)
				appStateSetter((prev) => ({ ...prev, ...payload }))
			},
		getPreferences: () => {
			return {
				flashCardProfLevel:
					state.flashCardProfLevel || state.profLevels?.[0]?.profLevel,
				flashCardSelectedCategory:state.flashCardSelectedCategory,
				flashCardSelectedCategoryId:
					state.flashCardSelectedCategoryId,
					sourceLanguage: state.sourceLanguage,
					targetLanguage: state.targetLanguage,
					flashCardSelectedLessonId: state.flashCardSelectedLessonId,
					flashCardSelectedLessonName: state.flashCardSelectedLessonName,
			};
		},
	};
};
