/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useEffect, useState } from "react";
import { useAppHook } from "../../../shared/hooks/useAppHook";
import { Layout } from "../../home/Layout";
import "bootstrap/dist/css/bootstrap.min.css";
import { CategoryCardStyle } from "./categoryCardStyle";
import { PiSpeakerSimpleLowFill } from "react-icons/pi";
import { Breadcrumb, Col, Container, Form, Image } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Row } from "react-bootstrap";
import LanguageSwapButton from "../../../shared/components/LanguageSwapButton";
import { useAppDispatch, useAppSelector } from "../../../store";
import styled from "styled-components";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import React from "react";
import { getSpeakUrl } from "../../../utils";
import NextButton from "./components/NextButton";

const StyledCard = styled.div`
width:77%;
padding:20px;
height:65vh;
position:relative;
border-radius:20px;
@media (min-width: 767px){
	margin-top: 0px;

`;

export const getLanguageNameCode = (value: string, code: string) => {
	if (value === "English") return "en";
	else return code ?? "en";
};
export const FlashCardCategoriesHome = () => {
	const [searchParams] = useSearchParams();
	const selectedLesson = useAppSelector(
		(state) => state?.lessons?.selectedLesson
	);
	const dispatch = useAppDispatch();

	const navigate = useNavigate();

	const languages = useAppSelector((state) => state.languages);

	const { getCategoriesById } = useAppHook();
	const [index, setIndex] = useState(0);
	const autoPlay = useAppSelector((state) => state.lessons.autoPlay);

		
	useEffect(() => {
		Promise.all([
			getCategoriesById({
				categoryId: searchParams.get("categoryId")!,
				sourceLanguage: languages?.sourceLanguage?.languageName!,
				targetLanguage: languages?.targetLanguage?.languageName!,
				customCategoryId: "",
				lessonId: selectedLesson?.lessonId ?? "",
			}),
		]).then();
	// 	fetch({
	// 			categoryId: searchParams.get("categoryId")!,
	// 			sourceLanguage: languages?.sourceLanguage?.languageName!,
	// 			targetLanguage: languages?.targetLanguage?.languageName!,
	// 			customCategoryId: "",
	// 			lessonId: searchParams.get("lessonId")!,
	// })
		
	
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [languages?.sourceLanguage, languages?.targetLanguage,selectedLesson?.lessonId]);

	const handleBackBtnPress = () => {
		navigate("/flashcards");
	};

	const handlePreviousBtnPress = () => {
		setIndex((i) => {
			if (i > 0) {
				return i - 1;
			} else {
				return i;
			}
		});
	};
	const handleNextBtnPress = () => {
		setIndex((i) => {
			if (i < selectedLesson?.items?.length - 1) {
				return i + 1;
			} else {
				return i;
			}
		});
	};

	const audioRef = React.useRef<HTMLAudioElement | null>(null);

	const handleSpeakPress = useCallback(async () => {
		const { language2Code, language2, language2_id } =
			selectedLesson?.items?.[index] || {};
		try {
			if (audioRef.current) {
				audioRef.current.pause();
				audioRef.current = null;
			}

			if (!language2_id || !language2Code || !language2) return;
			const result = await getSpeakUrl(language2_id, language2Code, language2);
			const { audioUrl, base64 } = result?.[0]?.result?.data as any;

			if (base64) {
				const audio = new Audio();
				audio.src = `data:audio/mp3;base64,${base64}`;
				try {
					audioRef.current = audio;
					audioRef.current.play();
				} catch (error) {}
			} else if (audioUrl) {
				const audio = new Audio(
					audioUrl +
						`?
						token=${Date.now().toString()}`
				);
				try {
					audioRef.current = audio;
					audioRef.current.play();
				} catch (error) {}
			}
		} catch (error) {}

		return () => {
			if (audioRef.current) {
				audioRef.current.pause();
				audioRef.current = null;
			}
		};
	}, [selectedLesson?.items, index]);

	useEffect(() => {
		if (!autoPlay) return;
		handleSpeakPress();
	}, [autoPlay, handleSpeakPress]);
	return (
		<Layout>
			<CategoryCardStyle>
				<Container>
					<Row className="my-4">
						<Col md={1} xs={1} sm={1}>
							<div>
								<Image
									role="button"
									onClick={handleBackBtnPress}
									src={"/icons/back.png"}
									style={{ width: 18, height: "auto" }}
								/>
							</div>
						</Col>
						<Col>
							
							<Breadcrumb>
								<Breadcrumb.Item href="#"  active >
									{searchParams?.get("profLevel")?.toLowerCase()}
								</Breadcrumb.Item>
								<Breadcrumb.Item href="#" active>
									{searchParams?.get("categoryName")?.toLowerCase()}
								</Breadcrumb.Item>
								<Breadcrumb.Item href="#" active>
									{searchParams?.get("lessonName")?.toLowerCase()}
								</Breadcrumb.Item>
							</Breadcrumb>
						</Col>
						<Col md={3} sm={12} xs={12} className="pe-0">
							<LanguageSwapButton />
						</Col>
					</Row>
					<Row>
						<center>
							<StyledCard className="bg-light d-flex align-items-center justify-content-center">
								<div
									role="button"
									onClick={handlePreviousBtnPress}
									style={{
										width: 32,
										height: 32,
										position: "absolute",
										top: "calc(50% - 16px)",
										left: -65,
									}}
								>
									<IoIosArrowDropleft
										className="text-primary"
										style={{
											fontSize: 48,
											position: "absolute",
											top: "calc(50% - 16px)",
											left: 0,
										}}
									/>
								</div>
								<div>
									<div
										className={
											"h1 fw-bolder mb-4 " +
											(selectedLesson?.items?.[
												index
											]?.language2Code?.toLowerCase() === "ta"
												? "ta-text"
												: "")
										}
									>
									
										{selectedLesson?.items?.[index]?.language2}
									</div>
									<div
										className={
											"h6  mt-4 " +
											(selectedLesson?.items?.[
												index
											]?.language1Code?.toLowerCase() === "ta"
												? "ta-text"
												: "")
										}
									>
										{selectedLesson?.items?.[index]?.language1}
									</div>
								</div>

								<div
									role="button"
									onClick={handleNextBtnPress}
									style={{
										width: 32,
										height: 32,
										position: "absolute",
										top: "calc(50% - 16px)",
										right: -50,
									}}
								>
									<IoIosArrowDropright
										className="text-primary"
										style={{
											fontSize: 48,
											position: "absolute",
											top: "calc(50% - 16px)",
											left: 0,
										}}
									/>
								</div>
								<Row
									style={{
										position: "absolute",
										bottom: 20,
										right: 20,
									}}
								>
									<Col>
										<Form role="button">
											<Form.Check
												type="switch"
												id="custom-switch"
												checked={autoPlay}
												onChange={() =>
													dispatch({
														type: "lessons/setAutoPlay",
														payload: !autoPlay,
													})
												}
											/>
										</Form>
									</Col>

									<Col>
										<PiSpeakerSimpleLowFill
											role="button"
											onClick={handleSpeakPress}
										/>
									</Col>
								</Row>
							</StyledCard>
							<div className="d-flex justify-content-end">
						{/* <NextButton/> */}
							</div>
						
						</center>
						<div className="d-flex justify-content-center mt-4">
							<div className="text-primary fw-bold">
								{index + 1}/{selectedLesson?.items?.length}
							</div>
						</div>
					</Row>
				</Container>
			</CategoryCardStyle>
		</Layout>
	);
};
