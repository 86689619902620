import { styled } from "styled-components";

export const dropdownContainer = `
    background-color: #DEDCE3;
    border: 2px solid #3F3A58;
    border-radius: 3vw;
    padding: 0.6vw;
		font-size: 1.2vw;
    font-weight: bold;
    font-style: italic;
    color: #9E9BB4;
`

export const homeStyle=`
.home-font{
  // font-size: 1.6vw;
  // color: #2A799B;
  text-align: justify;
}
.link {
	font-size: 1.45vw !important;
	font-style: italic;

}
a{
  color:#7c7ca1; 
}

.img-width{
  width: 25vw;
  height: 25vw;
  border-radius: 20%;
}
.IoChevronWidth{
  font-size:4vw;
}
h1 {
	font-size: 3vw !important;
	color: --var(app-color1);
	font-weight: 900;
}
@media  (max-width: 549px) {
  .home-font{
    font-size:4.5vw;
  }
  .link{
    font-size:4vw !important;
  }
  .mt-5{
    margin:0 !important;
  }
  .img-width{
    width: 55vw;
    height: 55vw;
  }
  .margin-top_l{
    margin-top:4vw;
  }
   h1{
    font-size:6vw !important;
  }


}
@media (max-width: 767px) and (min-width: 550px) {
  .home-font{
    font-size:3.5vw;
  }
  .link{
    font-size:3.5vw !important;
  }
  .mt-5{
    margin:0 !important;
  }
  .img-width{
    width: 40vw;
    height: 40vw;
  }
  .margin-top_l{
    margin-top:3vw;
  }
   h1{
    font-size:4.5vw !important;
  }

}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .home-font{
    font-size:2.8vw;
  }
  .link{
    font-size:3vw !important;
  }
  .mt-5{
    margin:0 !important;
  }
  .img-width{
    width: 35vw;
    height: 35vw;
  }
  .margin-top_l{
    margin-top:2vw;
  }
   h1{
    font-size:4vw !important;
  }
  .display-none{
    display:none;
  }

}

@media (max-width: 1200px) and (min-width: 992px) {
  .home-font{
    font-size:2vw;
  }
  .link{
    font-size:2.5vw !important;
  }
  .mt-5{
    margin:0 !important;
  }
  .mt-4{
    margin-top:0.5rem !important;
  }
  .img-width{
    width: 30vw;
    height: 30vw;
  }

 
}
`
export const DropdownContainer = styled.div`${dropdownContainer}`;
export const HomeStyle= styled.div`home-font{
  // font-size: 1.6vw;
  // color: #2A799B;
  text-align: justify;
}
.link {
	font-size: 1.45vw !important;
	font-style: italic;

}
a{
  color:#7c7ca1; 
}

.img-width{
  width: 25vw;
  height: 25vw;
  border-radius: 20%;
}
.IoChevronWidth{
  font-size:4vw;
}
h1 {
	font-size: 3vw !important;
	color: --var(app-color1);
	font-weight: 900;
}
@media  (max-width: 549px) {
  .home-font{
    font-size:4.5vw;
  }
  .link{
    font-size:4vw !important;
  }
  .mt-5{
    margin:0 !important;
  }
  .img-width{
    width: 55vw;
    height: 55vw;
  }
  .margin-top_l{
    margin-top:4vw;
  }
   h1{
    font-size:6vw !important;
  }


}
@media (max-width: 767px) and (min-width: 550px) {
  .home-font{
    font-size:3.5vw;
  }
  .link{
    font-size:3.5vw !important;
  }
  .mt-5{
    margin:0 !important;
  }
  .img-width{
    width: 40vw;
    height: 40vw;
  }
  .margin-top_l{
    margin-top:3vw;
  }
   h1{
    font-size:4.5vw !important;
  }

}

@media screen and (max-width: 991px) and (min-width: 768px) {
  .home-font{
    font-size:2.8vw;
  }
  .link{
    font-size:3vw !important;
  }
  .mt-5{
    margin:0 !important;
  }
  .img-width{
    width: 35vw;
    height: 35vw;
  }
  .margin-top_l{
    margin-top:2vw;
  }
   h1{
    font-size:4vw !important;
  }
  .display-none{
    display:none;
  }

}

@media (max-width: 1200px) and (min-width: 992px) {
  .home-font{
    font-size:2vw;
  }
  .link{
    font-size:2.5vw !important;
  }
  .mt-5{
    margin:0 !important;
  }
  .mt-4{
    margin-top:0.5rem !important;
  }
  .img-width{
    width: 30vw;
    height: 30vw;
  }

 
}`;