import styled from "styled-components";

export const VocabularyTable = styled.div`

  select {
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="1em" viewBox="0 0 320 512"><path d="M137.4 374.6c12.5 12.5 32.8 12.5 45.3 0l128-128c9.2-9.2 11.9-22.9 6.9-34.9s-16.6-19.8-29.6-19.8L32 192c-12.9 0-24.6 7.8-29.6 19.8s-2.2 25.7 6.9 34.9l128 128z" fill="rgb(74, 77, 119)"/></svg>');
    background-repeat: no-repeat;
    background-position: right center;
    background-size: 16px;
    width: 100%;
    right: 0px !important;
    text-align: center !important;
    color: #4B4C77 !important;
    font-size: 1.2vw !important;
    font-weight: bold !important;
    border: None;
    outline: none;
    white-space: nowrap;
  }
  option:hover{
    background-color:red !important;
  }

  thead, tr, th, tbody, td {
    background: none;
    border: none;
    padding: auto;
    margin: 0;
  }

  th, td {
    flex: 1;
  }

  td {
    font-weight: normal;
  }

  .responsive-table th, .responsive-table td {
    white-space: nowrap;
  }

  .my_vokab_table {
    background-color: #DEDCE3;
    border: 3px solid #4B4C77;
    border-radius: 20px;
    padding: 10px;
  }

  .my_vokab_table_font {
    font-size: 16px !important;
    font-weight: bold !important;
    color: #4B4C77 !important;
  }

  .plus-border {
    border: 3px solid #3F3A58;
    border-radius: 50%;
    font-size: 1.5rem;
  }

  .plus-table-border {
    border: 4px solid #4B4C77 !important;
  }

  table {
    border-collapse: collapse;
    max-width: 100%; /* Prevent the table from shrinking */
  }

  thead {
    position: sticky;
    top: 0;
  }

  tbody {
    max-height: calc(100vh - 210px);
    overflow-y: auto;
    
  }
  .border-left{
    border-left:2px solid #4A4C75;
  }
  .tr-11{
    width:98%;
  }
  .tr-1{
    width:2%;
  }
  .pointer{
    cursor: pointer;
  }
  .text-truncate {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
 







  .sc-fmKFmJ kFifGe {
    background-color: red !important;
  }

  @media (min-width: 992px) {
    table {
      width: 100%; /* Set the table width to 100% on larger screens (lg and above) */
    }
  }
  
  @media (max-width: 549px) {
    /* Add your responsive styles for smaller screens here */
  }

  @media (max-width: 767px) and (min-width: 550px) {
    /* Add your responsive styles for medium-sized screens here */
  }

  @media screen and (max-width: 991px) and (min-width: 768px) {
    /* Add your responsive styles for larger screens here */
  }

  @media (max-width: 1200px) and (min-width: 992px) {
    /* Add your responsive styles for extra-large screens here */
  }
`;
