import {
	ICategory, ILanguage, CategoriesTableFilterParams, IProfLevel,
	IGrammarCategory, ICategoriesTableData, CategoriesByProfParams, CategoriesById, MyCategoriesByUserParams, IMyCategoryPayload, IMyCategoryDetailsPayload, ILesson
} from "@vokab/shared/src/types";
import { apiCall, apiCallWithLoading } from "./";
import { ICategoriesByIdData, ICategoriesByProfData, ICustomCategoriesByUserIdData } from "../shared/contexts/AppContextWrapper";
import { date } from "yup";

export const allCategories = () => apiCall<ICategory[]>({ method: 'GET', url: 'common/categories' });
export const allLanguages = () => apiCall<ILanguage[]>({ method: 'GET', url: `common/languages` });
export const categoriesTableRequest = (filterParams: CategoriesTableFilterParams) => (
	apiCall<ICategoriesTableData>({ method: 'POST', url: 'common/categories/grid', data: filterParams })
);
export const profLevelsRequest = () => apiCallWithLoading<IProfLevel[]>({ method: 'GET', url: 'common/prof-levels' });
export const grammarCategoryRequest = () => apiCall<IGrammarCategory[]>({ method: 'GET', url: 'common/pos' });
export const categoriesByProf = (params: CategoriesByProfParams) => (
	apiCall<ICategoriesByProfData>({ method: 'POST', url: 'common/categoriesByProf', data: params })
);
export const categoriesByIdRequest = (filterParams: CategoriesById) => (
	apiCall<ICategoriesByIdData>({ method: 'POST', url: 'common/categoryDetailsById', data: filterParams })
);
export const myCategoriesByUserIdRequest = (filterParams: MyCategoriesByUserParams) => (
	apiCall<ICustomCategoriesByUserIdData>({ method: 'POST', url: 'common/myCategories', data: filterParams })
);

export const createMyCategoryPayload = (payload: IMyCategoryPayload) => apiCallWithLoading<string>({
	method: 'POST',
	url: 'common/createMyCategory',
	data: payload
});

export const addMyCategoryDetailsRequest = (payload: IMyCategoryDetailsPayload) => apiCallWithLoading<string>({
	method: 'POST',
	url: 'common/addCategoryDetails',
	data: payload
});

export const getLessonsByCategoryId = (categoryId: string) => apiCallWithLoading<{
	totalRecords: number,
	result: ILesson[]
}>({
	method: 'POST',
	url: 'common/lessonsByCategoryId',
	data: { categoryId }
})
