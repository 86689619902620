/* eslint-disable jsx-a11y/anchor-is-valid */
import TextInput from "../../shared/components/form/TextInput";
import { LoginButton } from "../../styled-components/common";
import { FormikWithWrapper } from "../../shared/contexts/FormikWrapper";
import { LoginPayload } from "@vokab/shared/src/types";
import { Link, useSearchParams } from "react-router-dom";
import { FormStyle } from "./style";
import Form from "react-bootstrap/Form";
import { FormikProps } from "formik";
import {  userLogin } from "@vokab/shared/src/schemas";
import { useAppHook } from "../../shared/hooks/useAppHook";
import { IoMdArrowRoundBack } from "react-icons/io";
const initialValues = {
	password: "",
	confirmPassword: "",
};

export default function PasswordResetRequest() {
	const { sendForgotPasswordLink } = useAppHook();
	const [searchParams] = useSearchParams();
	const userId = searchParams.get("userId");




	return (
		<>
			<FormStyle
			
			>
				<FormikWithWrapper
					enableReinitialize
					validateOnMount
					initialValues={{
						email:""
					}}
					validationSchema={userLogin.omit(["password"])}
					onSubmit={async value => await sendForgotPasswordLink(value)}
				>
					{(props: FormikProps<LoginPayload>) => (
						<Form className="pt-3 " onSubmit={props.handleSubmit} noValidate>

							<div
								style={{
									display: "flex",
									justifyContent: "center",
								}}
								className="form-title"
							>
								<b>forgot your password?</b>
							</div>
							<TextInput
								name="email"
								type="email"
								placeholder="email@address.com"
								className="mt-2"
							/>
							<div className="d-flex align-items-baseline mt-4">
								
									
									<Link to="/auth/login" className="link-color fw-bold" style={{ textDecoration: "none" }}>
										<IoMdArrowRoundBack /> back to login
									</Link>
			
							</div>
							<div className=" d-flex mt-4">
								<LoginButton
									type="submit"
									className="btn btn-primary mx-auto rounded-5  px-4 py-2 d-flex align-items-center justify-content-center"
								>
									<b style={{ fontSize: "1.4rem" }} className="text-center p-0">
										reset my password
									</b>
								</LoginButton>
							</div>
						</Form>
					)}
				</FormikWithWrapper>
			</FormStyle>
		</>
	);
}
