import React, { useContext, createContext, useState } from 'react';
import { ICategoriesTableData, ICategory, ICategoryById, ICategoryByProf, ICustomCategory, IGrammarCategory, ILanguage, ILesson, IProfLevel } from '@vokab/shared/src/types';

export interface ICategoriesByIdData {
	result: ICategoryById[];
}

export interface ICustomCategoriesByUserIdData {
	result: ICustomCategory[];
}

export interface ICategoriesByProfData {
	result: ICategoryByProf[];
}
export interface IAppContextState {
	categories: ICategory[];
	categoriesByProf: ICategoriesByProfData;
	languages: ILanguage[];
	profLevels: IProfLevel[];
	categoriesTableData: ICategoriesTableData;
	pos: IGrammarCategory[];
	categoriesById: ICategoriesByIdData;
	myCategories: ICustomCategoriesByUserIdData;
	flashCardProfLevel: string;
	flashCardsMyCustomCategory: string;
	flashCardSelectedCategory:string
	flashCardSelectedCategoryId?:string
	flashCardSelectedLessonId?:string,
	flashCardSelectedLessonName?:string,
	categoryLessons: {
		result:ILesson[]
	},
	targetLanguage:string,
	sourceLanguage:string

}

export interface AppContextProps {
	children: React.ReactNode;
}

const initialValues: IAppContextState = {
	categories: [],
	categoriesByProf: { result: [] },
	languages: [],
	profLevels: [],
	categoriesTableData: { totalRecords: 0, result: [] },
	pos: [],
	categoriesById: { result: [] },
	myCategories: { result: [] },
	flashCardProfLevel: '',
	flashCardSelectedCategory: '',
	flashCardSelectedCategoryId: '',
	flashCardsMyCustomCategory: '',
	flashCardSelectedLessonId: '',
	sourceLanguage: "English",
	targetLanguage: "Tamil",
	flashCardSelectedLessonName: "",
	categoryLessons: {
		result:[]
	},
};

export interface IAppContext extends IAppContextState {
	appStateSetter: React.Dispatch<React.SetStateAction<IAppContextState>>;
}

const AppContext = createContext<IAppContext | null>(null);

export const useAppContextWrapper = () => useContext(AppContext) as IAppContext;

export const AppContextWrapper = ({ children }: AppContextProps) => {
	const [appState, appStateSetter] = useState<IAppContextState>(initialValues);

	return (
		<AppContext.Provider value={{
			...appState,
			appStateSetter
		}}>
			{children}
		</AppContext.Provider>
	);
}