import React from 'react'
import { Spinner } from 'react-bootstrap'

export default function AppSpinner() {
	return (
		<div style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: "calc(100vh - 100px)"
		}}>
			<Spinner animation="grow" />
		</div>
	)
}
