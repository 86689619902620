import { useNavigate } from "react-router-dom";
import { userLogin, userProfileUpdate, userResetPassword, userSignup } from "../../api/auth";
import { IUpdateProfile, LoginPayload, ResetPasswordPayload, SignupPayload } from "@vokab/shared/src/types";
import { useNotificationContextWrapper } from "../contexts/NotificationContextWrapper";
import { useUserHook } from "./useUserHook";

export const useAutHook = () => {
	const navigate = useNavigate();
	const { getMe } = useUserHook();
	const { notifyError, notifySuccess } = useNotificationContextWrapper();

	const login = async (payload: LoginPayload) => {
		const [res, error] = await userLogin(payload);
		if (error)
			notifyError(error);
		else {
			localStorage.setItem('authToken', res.result.data);
			getMe();
		}
	}

	const signup = async (payload: SignupPayload) => {
		const [, error] = await userSignup(payload);
		if (error)
			notifyError({ message: error?.response?.data?.message });
		else {
			notifySuccess('Registered successfully');
			navigate('/auth/login');
		}
	}

	const resetPassword = async (payload: ResetPasswordPayload) => {
		try {
			
		const result = await userResetPassword(payload);
			if(result?.[0]?.status==="200"){
				notifySuccess('password reset successfully');
				navigate('/auth/login');
			} else{
					notifySuccess('password reset successfully');

				navigate('/auth/login');
			}
			
	
		} catch (error:any) {
			notifyError({ message: error?.message });
			
		}
	}
	const changePassword = async (payload: ResetPasswordPayload) => {
		const [, error] = await userResetPassword(payload);
		if (error)
			notifyError({ message: error });
		else {
			notifySuccess('password updated successfully');
		}
	}
	const logout = () => {
			localStorage.removeItem('authToken');
			window.location.href = '/';
	}
	const updateProfile = async (payload: IUpdateProfile) => {
		const [, error] = await userProfileUpdate(payload);
		if (error)
			notifyError({ message: error });
		else {
			notifySuccess('profile updated successfully');
		}
	}

	return {
		login,
		signup,
		logout,
    resetPassword,
		changePassword,
		updateProfile
	}
}