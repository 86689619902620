export default function RegistrationButton(
	props: React.DetailedHTMLProps<
		React.HTMLAttributes<HTMLDivElement>,
		HTMLDivElement
	>
) {
	return (
		<div style={{
			borderRadius:30,
			width:300,
			height:50,
			backgroundColor:"rgb(209, 233, 243)"

		}} className="border-4 border border-primary text-center d-flex justify-content-center align-items-center ">
			<p role="button"  className="p-0 m-0 text-primary fw-bold" >
			<a
			style={{
				textDecoration:"none",

			}}
				href="https://forms.gle/PbhkGo1mAyScYLTg6"
				target="_blank"
				type="submit"
				rel="noreferrer"
			>
	register
			</a>
			</p>
		</div>
	);
}
