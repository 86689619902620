import 'bootstrap/dist/css/bootstrap.min.css';
import { Layout } from '../home/Layout';
import AboutUs from './About';
import Team from './Team';
import ForgotPassword from '../forgotPassword';


const AboutUsHome = () => {
    return (
        <Layout>
            <div>
                <AboutUs />
                {/* <Team /> */}
            </div>
        </Layout>
    );
};

export default AboutUsHome;