import React, { useMemo } from "react";
import { Col, Dropdown, Row,Image } from "react-bootstrap";
import { useAppHook } from "../hooks/useAppHook";
import styled from "styled-components";
import {  useAppDispatch, useAppSelector } from "../../store";
import { languageActions } from "../../store/slices/languageSlice";
import { ILanguage } from "@vokab/shared/src/types";
import api from "../../store/services/api";
const Pointer=styled.div`
cursor:pointer;
`

function LanguageSwapButton() {

const languages=useAppSelector(state=>state.languages)
const {data}=api.useGetAllLanguagesQuery()
const dispatch=useAppDispatch()


	const handleSourceLanguageChange = (value: Pick<ILanguage,"languageId"|"languageName">) => {

		dispatch(languageActions.setSourceLanguage(value));

	};
	const handleTargetLanguageChange = (value: Pick<ILanguage,"languageId"|"languageName" |"languageCode">) => {
		dispatch(languageActions.setTargetLanguage(value));
	};
	const handleSwapLanguage = () => {
		dispatch(languageActions.setSourceLanguage(languages.targetLanguage));
		dispatch(languageActions.setTargetLanguage(languages.sourceLanguage));
		
	};

	const languageOptions = useMemo(
		() =>
			[
				{
					languageName: "English",
					languageFamilyId: "",
					languageFamilyName: "default",
					languageId: "",
					languageCode:""
				},
			].concat(
				data?.result?.data?.map((d) => ({
					languageName: d.languageName,
					languageFamilyId: d.languageFamilyId,
					languageFamilyName: d.languageFamilyName,
					languageId: d.languageId,
					languageCode: d.languageCode,
				})) ?? []
			) ?? null,
		[data?.result?.data]
	);
	const groupingLanguages: { [key: string]: string[] } = languageOptions.reduce(
		(
			family: any,
			language: {
				languageFamilyName: string;
				languageName: string;
				languageId: string;
				languageCode:string
				
				
				
			}
		) => {
			const key: string = language.languageFamilyName;
			if (!family[key]) {
				family[key] = [];
			}
			family[key].push({
				languageName: language.languageName,
				languageId: language.languageId,
				languageCode:language.languageCode
			});
			return family;
		},
		[]
	);
	return (
		<Row className="align-items-center">
			<Col>
				<Dropdown className="d-flex justify-content-center ">
					<Dropdown.Toggle
						className="text-center w-75"
						variant="light"
						id="dropdown-basic"
					>
						{languages?.sourceLanguage?.languageName?.toLowerCase()}
					</Dropdown.Toggle>

					<Dropdown.Menu className="w-75">
						{Object.entries(groupingLanguages).map((l, i) => (
							<React.Fragment key={i.toString()}>
							<Pointer >
								<option key={i} value={l[0]} disabled>
									<b>{l[0].toLowerCase()}</b>
								</option>
								</Pointer>
								{l[1].map((x: any, y) => (
									<Pointer onClick={()=>handleSourceLanguageChange(x)}>
									<option key={y} value={x.languageName}>
										{x.languageName.toLowerCase()}
									</option>
									</Pointer>
								))}
							</React.Fragment>
						))}
					</Dropdown.Menu>
				</Dropdown>
			</Col>
			<Col md={1} lg={1} >
			<Pointer onClick={handleSwapLanguage}>
			<Image	 src={"/icons/swap.png"} style={{width:18, height:"auto"}}/>
			</Pointer>
			</Col>
			<Col>
				<Dropdown className="d-flex justify-content-center ">
					<Dropdown.Toggle
						className="text-center w-75"
						variant="light"
						id="dropdown-basic"
					>
						{languages?.targetLanguage?.languageName?.toLowerCase()}
					</Dropdown.Toggle>

					<Dropdown.Menu className="w-75">
						{Object.entries(groupingLanguages).map((l, i) => (
							<React.Fragment key={i.toString()}>
							<Pointer>
								<option key={i} value={l[0]} disabled>
									<b>{l[0].toLowerCase()}</b>
								</option>
								</Pointer>
								{l[1].map((x: any, y) => (
									<Pointer onClick={()=>handleTargetLanguageChange(x)}>
									<option key={y} value={x.languageName}>
										{x.languageName.toLowerCase()}
									</option>
									</Pointer>
								))}
							</React.Fragment>
						))}
					</Dropdown.Menu>
				</Dropdown>
			</Col>
		</Row>
	);
}

export default  React.memo(LanguageSwapButton)