import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ICategoryById, ILesson } from "@vokab/shared/src/types";

const lessonSlice = createSlice({
	name: "lessons",
	initialState: {
		data: [] as ILesson[],
		selectedLesson: {} as ILesson,
		autoPlay: false,
	},
	reducers: {
		// Define your actions and reducers here
		setLessons(state, action: PayloadAction<ILesson[]>) {
			state.data = action.payload;
	
			
			state.selectedLesson = state?.selectedLesson?.lessonId
				? state?.selectedLesson
				: action.payload[0];
		},
		setSelectedLesson(state, action: PayloadAction<ILesson>) {
			state.selectedLesson = action.payload;
		},
		setSelectedLessonItems(state, action: PayloadAction<ICategoryById[]>) {
			state.selectedLesson.items = action.payload;
		},
		setDefaultSelectedLesson(state,payload:PayloadAction<ILesson>) {
			state.selectedLesson = payload.payload;
		},
		setAutoPlay(state, action: PayloadAction<boolean>) {
			state.autoPlay = action.payload;
		}
	},
});

export const { actions: lessonActions, reducer: lessonReducer } = lessonSlice;
