import Table from 'react-bootstrap/Table';
import { Select } from '../../styled-components/common';
import { styled } from 'styled-components';
import { AiFillCaretLeft } from 'react-icons/ai';
import { AiFillCaretRight } from 'react-icons/ai';
import { useAppContextWrapper } from '../../shared/contexts/AppContextWrapper';
import { Row } from 'react-bootstrap';
import React, { useEffect, useMemo, useState } from 'react';
import { CategoriesTableFilterParams, ICategoriesTable, ICategoriesTableData } from '@vokab/shared/src/types';
import { useAppHook } from '../../shared/hooks/useAppHook';
import { FiPlus } from 'react-icons/fi';
import { FiMinus } from 'react-icons/fi';
import { VocabularyTable } from './vocabularyTable';
import { getLanguageNameCode } from '../flashCards/categories';
import AddMyCategoryDialog from './AddMyCategoryDetails';
import { useUserContextWrapper } from '../../shared/contexts/UserContextWrapper';

const TableScrollContainer = styled.div`
		//  height: calc(100vh - 110px);
		// overflow-y: auto;
`

export interface ISelected {
	selectedWord: string;
	grammarAssociationId: string;
	categoryId: string;
}

export default function CategoryListTable() {
	const [state, setState] = useState(true);
	const { isLoggedIn, user } = useUserContextWrapper();
	const [grammarCategoryName, setGrammarCategoryName] = useState('');
	const [langs, setLanguages] = useState({
		language1: 'English',
		language2: 'Tamil',
		language3: 'Telugu',
		language4: 'Malayalam',
		language5: 'Hindi',
		language6: 'Kannada'
	});
	const [modalShow, setModalShow] = useState(false);
	const [selected, setSelected] = useState<ISelected>({
		selectedWord: '',
		grammarAssociationId: '',
		categoryId: ''
	});
	const [newEle, setNewEle] = useState<string[]>([]);
	const [tableItems, setTableItems] = useState<ICategoriesTableData>({ totalRecords: 0, result: [] });
	const { categories, languages, pos, categoriesTableData, myCategories } = useAppContextWrapper();
	const categoryIdValue = useMemo(() => categories[0]?.categoryId, [categories])
	const [categoryName, setCategoryName] = useState('');
	const [isCustomCategory, setIsCustomCategory] = useState(false);
	useEffect(() => {
		if (categoryIdValue)
			setCategoryName(categoryIdValue)
	}, [categoryIdValue]);
	const [filterItems, setFilterItems] = useState<CategoriesTableFilterParams>({
		start: 0, limit: 20
	});
	const speechHandler = (msg: string, langCode: string) => {

		const sourceMsg = new SpeechSynthesisUtterance(msg);
		sourceMsg.lang = langCode;
		window.speechSynthesis.speak(sourceMsg);
	}

	const languageOptions = useMemo(() => [
		{ languageName: 'English', languageFamilyId: '', languageFamilyName: 'default', languageId:''}
	].concat(languages.map(d => ({ languageName: d.languageName, languageFamilyId: d.languageFamilyId, languageFamilyName: d.languageFamilyName,languageId:d.languageId }))) ?? null, [languages]);

	const groupingLanguages: { [key: string]: string[] } = languageOptions.reduce((family:any, language) => {
		const key: string = language.languageFamilyName;
		if (!family[key]) {
			family[key] = [];
		}
		family[key].push({languageName:language.languageName,languageId:language.languageId});
		return family;
	},[]);


	const addDetailsHandler = (word: string, id: string, categoryId: string) => {
		setSelected((prev: ISelected) => ({
			...prev, selectedWord: word, grammarAssociationId: id, categoryId: categoryId
		}));
		setModalShow(!modalShow)
	}
	const getHeaderDropDown = (value: string) => {
		if (value === 'language3')
			return langs.language3
		if (value === 'language4')
			return langs.language4
		if (value === 'language5')
			return langs.language5

		return langs.language6
	}
	const getLanguageOptions = (languageId: string) => {
		const newElementsLanguages = newEle.map(kk => getHeaderDropDown(kk))
		const languages = [langs.language1, langs.language2].concat(newElementsLanguages)
		// const currentLangFilter = languages.filter(d => d !== languageId)
		const filterOptions = groupingLanguages
		// .filter(v => !currentLangFilter.includes(v.languageName))

		return filterOptions
	}
	useEffect(() => {
		if (filterItems.start === 0)
			setTableItems(categoriesTableData);
		else
			setTableItems(prev => ({
				...prev, totalRecords: categoriesTableData?.totalRecords,
				result: [...prev.result, ...categoriesTableData?.result]
			}));
	}, [filterItems, setTableItems, categoriesTableData, categories]);

	useEffect(() => {
		if (categories.length) {
			const filters = {
				...filterItems,
				categoryId: categoryName
			};
			setFilterItems(filters);
		}
	}, [categories, categoryName, filterItems]);
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const selectedLanguages = [langs.language1, langs.language2, langs.language3, langs.language4, langs.language5, langs.language6]

	const userId = useMemo(() => user?.userId, [user])
	const filterParams = useMemo(() => ({
		...filterItems,
		languageIds: selectedLanguages,
		grammarCategoryName: grammarCategoryName,
		categoryId: categoryName,
		userId: userId,
		isCustomCategory: isCustomCategory
	}), [filterItems, selectedLanguages, grammarCategoryName, categoryName, userId, isCustomCategory]);
	useEffect(() => {
		setFilterItems(filterParams);
	}, [langs, grammarCategoryName, categories, categoryName, isCustomCategory, filterParams]);

	useEffect(() => {
		if (categories.length && selectedLanguages.length && filterItems.categoryId)
		{
			
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [filterItems]);

	const onCategoryChanged = (categoryId: string) => {
		setCategoryName(categoryId)
		if (myCategories.result.map(f => f.categoryName).includes(categoryId)) {
			setIsCustomCategory(true)
		} else {
			setIsCustomCategory(false)
		}
	}
	const onLanguageChanged = (languageIds: string, key: string) => {
		if (key === 'language1')
			setLanguages(prev => ({
				...prev, language1: languageIds
			}));
		else if (key === 'language2')
			setLanguages(prev => ({
				...prev, language2: languageIds
			}))
		else if (key === 'language3')
			setLanguages(prev => ({
				...prev, language3: languageIds
			}))
		else if (key === 'language4')
			setLanguages(prev => ({
				...prev, language4: languageIds
			}))
		else if (key === 'language5')
			setLanguages(prev => ({
				...prev, language5: languageIds
			}))
		else
			setLanguages(prev => ({
				...prev, language6: languageIds
			}))
	}

	const onGrammarCategoriesChange = (value: string) => {
		setGrammarCategoryName(value);
	}

	const onClick = () => {
		const key = `language${newEle.length + 3}`
		const idCollection = [...newEle];
		if (newEle.length < 4) {
			idCollection.push(key);
			setNewEle(idCollection)
		}
	}

	const onRemoveHandler = () => {
		const idCollection = [...newEle];
		idCollection.pop();
		setNewEle(idCollection)
	}

	const getLanguageName = (value: string, ct: ICategoriesTable, word: string) => {
		if (value === 'English')
			return ct.word
		else
			return word ?? 'No Data'
	}



	const getValue = (value: string, val: ICategoriesTable) => {
		if (value === 'language3')
			return getLanguageName(langs.language3, val, val.language2)
		if (value === 'language4')
			return getLanguageName(langs.language4, val, val.language3)
		if (value === 'language5')
			return getLanguageName(langs.language5, val, val.language4)

		return getLanguageName(langs.language6, val, val.language5)
	}



	return (
		<div>
			<AddMyCategoryDialog
				show={modalShow}
				setShow={setModalShow}
				state={selected}
			/>
			<VocabularyTable className='ms-5'>
				<Row className='m-0 col-11 mx-auto'>
					<TableScrollContainer id='scrollableDiv ' style={{
						height: 'calc(100vh - 140px)'
					}} className='p-0'>

						<Table responsive>
							<thead className='d-flex justify-content-between align-items-center flex-wrap col-12'>
								<div className='d-flex col-12 flex-wrap  justify-content-center align-items-center'>
									<tr className=' col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-11 d-flex justify-content-between align-items-center p-0'>
										{
											state && (
												<th className='ps-0'>
													<div className='my_vokab_table flex-wrap my_vokab_table_font d-flex align-items-center justify-content-center'>
														<div className="tr-1 text-left">
															<AiFillCaretLeft onClick={() => setState(!state)} />
														</div>
														<div className='tr-11 '>
															<Select
																name="category"
																id="category"
																onChange={(e) => onCategoryChanged(e.target.value)}
																value={filterItems.categoryId}
															>
																{isLoggedIn ? (
																	<>
																		<option value="" disabled>my categories</option>
																		<hr />
																		{myCategories.result.map((mC, i) => {
																			return <option key={i} value={mC.categoryName}>{mC?.categoryName.toLowerCase()}</option>
																		})}
																		<option value="" disabled>public categories</option>
																		<hr />
																		<>
																			{categories.map((c, i) => (
																				<option key={i} value={c?.categoryId} className='scrollbar'>
																					{c?.categoryName.toLowerCase()}
																				</option>
																			))}
																		</>

																	</>
																) : (
																	<>
																		<option value="" disabled>public categories</option>
																		{categories.map((c, i) => (
																			<option key={i} value={c?.categoryId} className='scrollbar'>
																				{c?.categoryName.toLowerCase()}
																			</option>
																		))}
																	</>
																)}
															</Select>

														</div>
													</div>
												</th>
											)}
										<th >
											<div className='d-flex justify-content-center align-items-center  my_vokab_table my_vokab_table_font'>

												{!state
													&&
													(<div className=''>
														<AiFillCaretRight onClick={() => setState(!state)} /> </div>)}

												<div className='d-flex flex-wrap d-flex flex-wrap  flex-grow-1 '>

													<div className='col-12 '>
														<Select name="profLevel" id="profLevel"
															value={grammarCategoryName} className='p-0 text-truncate'
															onChange={e => onGrammarCategoriesChange(e.target.value)} title='parts of speech(POS)'>
															<option value="" selected>part of speech(POS)</option>
															{pos.map((p, i) => (

																<option key={i} value={p.grammarCategoryName}>{p.grammarCategoryName.toLowerCase()}</option>
															))}
														</Select>
													</div>
												</div>
											</div>
										</th>
										<th>
											<div className='d-flex justify-content-center align-items-center  my_vokab_table my_vokab_table_font'>
												{/* <div className="tr-1 text-left">
													<FaSearch />
												</div> */}
												<div className='col-12 p-0'>
													<Select id={'language1'} onChange={e => onLanguageChanged(e.target.value, 'language1')} value={langs.language1} className='p-0'>
														{
															Object.entries(getLanguageOptions(langs.language1)).map((l, i) => (
																<>
																<option key={i} value={l[0]} disabled><b >{l[0].toLowerCase()}</b></option>
																{l[1].map((x:any,y)=><option key={y} value={x.languageName}>{x.languageName.toLowerCase()}</option>)}
																</>
															))
														}
													</Select>
												</div>
											</div>
										</th>
										<th className='pe-0'>
											<div className='d-flex justify-content-center align-items-center  my_vokab_table my_vokab_table_font'>
												{/* <div className="tr-1 text-left">
													<FaSearch />
												</div> */}
												<div className='col-12 p-0'>
													<Select id={'language2'} onChange={e => onLanguageChanged(e.target.value, 'language2')} value={langs.language2} className='p-0'>
													{
															Object.entries(getLanguageOptions(langs.language2)).map((l, i) => (
																<>
																<option key={i} value={l[0]} disabled><b >{l[0].toLowerCase()}</b></option>
																{l[1].map((x:any,y)=><option key={y} value={x.languageName}>{x.languageName.toLowerCase()}</option>)}
																</>
															))
														}
													</Select>
												</div>
											</div>
										</th>
										{
											newEle.map((v, key) => <th key={key} className='pe-0'>
												<div className='d-flex justify-content-center align-items-center  my_vokab_table my_vokab_table_font'>
													{/* <div className="tr-1 text-left">
														<FaSearch />
													</div> */}
													<div className='col-12 p-0'>
														<Select id={v} onChange={e => onLanguageChanged(e.target.value, v)} value={getHeaderDropDown(v)} className='p-0'>
																{
															Object.entries(getLanguageOptions(getHeaderDropDown(v))).map((l, i) => (
																<>
																<option key={i} value={l[0]} disabled><b >{l[0].toLowerCase()}</b></option>
																{l[1].map((x:any,y)=><option key={y} value={x.languageName}>{x.languageName.toLowerCase()}</option>)}
																</>
															))
														}
														</Select>
													</div>
												</div>
											</th>)
										}

									</tr>
									<tr className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-1 d-flex justify-content-between align-items-center text-center pointer'>
										<th className='p-0 '>
											<div className="sound col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12" style={{
												display: 'flex',
												gap: 3,
												alignItems: 'center'
											}}>
												<FiPlus onClick={onClick} size={35} className='plus-border plus-table-border vokab-table-font p-0' style={{ background: '#D3D2D8' }} />
												<FiMinus onClick={onRemoveHandler} size={35} className='plus-border plus-table-border vokab-table-font p-0' style={{ background: '#D3D2D8' }} />

											</div>

										</th>
									</tr>
								</div>
							</thead>
							<tbody className='d-flex justify-content-center align-items-baseline flex-wrap scrollbar scrollbar-primary '>
								{tableItems?.result?.map((ct, i) => (
									<React.Fragment key={i}>
										<div className="d-flex col-12 flex-wrap  justify-content-center align-items-baseline">
											<tr className='col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-11 d-flex justify-content-between align-items-center my_vokab_table my_vokab_table_font  mt-3 py-2 px-0'>
												{state && (
													<td className='d-flex justify-content-between px-0'><div className='mx-auto'>
														{ct?.categoryName ?? ''}</div>

													</td>
												)}
												<td className='d-flex justify-content-between px-0 border-left' >

													<div className='mx-auto'>
														{grammarCategoryName ? grammarCategoryName : ct.grammarCategoryName}
													</div>

												</td>

												<td className='d-flex justify-content-between px-0 border-left' onClick={() => speechHandler(getLanguageName(langs.language1, ct, ct.language0), getLanguageNameCode(langs.language1, ct.language0Code))}><div className="mx-auto">
													{getLanguageName(langs.language1, ct, ct.language0)}</div>


												</td>
												<td className='text-center px-0 border-left' onClick={() => speechHandler(getLanguageName(langs.language2, ct, ct.language1), getLanguageNameCode(langs.language2, ct.language1Code))}>{getLanguageName(langs.language2, ct, ct.language1)}</td>
												{newEle.map(f => <td key={f} className='d-flex justify-content-between px-0 text-wrap border-left'>

													<div className="mx-auto">
														{getValue(f, ct)}</div>

												</td>)}

											</tr>

											<tr className='col-xxl-1 col-xl-1 col-lg-1 col-md-1 col-1 d-flex justify-content-between align-items-center text-center pointer'>
												<td onClick={() => addDetailsHandler(ct.word, ct.vocabularyGrammarAssociationId, ct.categoryId)} className='p-0'>
													<div className="sound col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-12 ">
														<FiPlus size={35} className='plus-border plus-table-border vokab-table-font ' />
													</div>
												</td>
											</tr>
										</div>
									</React.Fragment>
								))}
								{!tableItems.result?.length && (
									<tr>
										<td colSpan={5}>No Data</td>
									</tr>
								)}
							</tbody>
						</Table>
					</TableScrollContainer>
				</Row >
			</VocabularyTable>
		</div>
	);
}