/* eslint-disable jsx-a11y/anchor-is-valid */
import TextInput from "../../shared/components/form/TextInput";
import { LoginButton } from "../../styled-components/common";
import { FormikWithWrapper } from "../../shared/contexts/FormikWrapper";
import { userLogin } from "@vokab/shared/src/schemas";
import { LoginPayload } from "@vokab/shared/src/types";
import { useEffect, useState } from "react";
import { Link, useNavigate, useRoutes } from "react-router-dom";
import { useUserContextWrapper } from "../../shared/contexts/UserContextWrapper";
import { useAutHook } from "../../shared/hooks/useAuthHook";
import { FormStyle } from "./style";
import Form from "react-bootstrap/Form";
import { FormikProps } from "formik";


export default function Login() {
	const { isLoggedIn } = useUserContextWrapper();

	const navigate = useNavigate();
	const { login } = useAutHook();

	const submit = (values: LoginPayload) => {
		login(values);
	};
	useEffect(() => {
		if (isLoggedIn) navigate("/");
	}, [navigate, isLoggedIn]);

	return (
		<FormStyle>
			<div>
				<h3 className="text-center mt-3 form-title">
					<b>login your account</b>
				</h3>
				<FormikWithWrapper
					enableReinitialize
					validateOnMount
					initialValues={{
						email:"",
						password: "",
					}}
					validationSchema={userLogin}
					onSubmit={submit}
				>
					{(props: FormikProps<LoginPayload>) => (
						<Form className="pt-3" onSubmit={props.handleSubmit} noValidate>
							<TextInput
								name="email"
								type="email"
								placeholder="email@address.com"
							/>
							<TextInput
								name="password"
								type="password"
								placeholder="password"
							/>
							<div className="d-flex align-items-center justify-content-between flex-column">
								<div className=" pt-3 d-flex justify-content-end w-100 ">
								
								
									<Link to="/auth/password/reset/request" className="link-color fw-bold" style={{ textDecoration: "none" }}>
									<b className="link-color">forgot password?</b>
									</Link>
								</div>

								<div className="text-center ">
									<LoginButton
										type="submit"
										className="btn btn-primary form-button mx-auto m-3 rounded-5 "
									>
										log in
									</LoginButton>
								</div>
							</div>
						</Form>
					)}
				</FormikWithWrapper>
			</div>
		</FormStyle>
	);
}
