import { useEffect } from 'react';
import { useRoutes } from 'react-router-dom';
import { useUserHook } from '../shared/hooks/useUserHook';
import Home from '../pages/home';
import FlashcardsRouter from './flashCardsRoutes';
import { VocabularyHome } from '../pages/vocabulary';
import AboutUs from '../pages/about_us';
import AuthRoutes from './authRoutes';
import { EventsHome } from '../pages/events';
import Profile from '../pages/Myprofile/profile';
import ChangePassword from '../pages/ChangePassword/password';
import ForgotPassword from '../pages/forgotPassword';
import VocabularyHomeStatic from '../pages/vokab';


const RootRouter = () => {
  const { getMe } = useUserHook();
  useEffect(() => {
    getMe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const routes = useRoutes([
    {
      path: '/',
      element: <Home />
    },
    {
      path: '/auth/*',
      element: <AuthRoutes />
    },
    {
      path: '/flashcards/*',
      element: <FlashcardsRouter />
    },
		{
      path: '/vokab/*',
      element: <VocabularyHomeStatic />
    },
    {
      path: '/courses/*',
      element: <VocabularyHome />
    },
   
    {
      path: '/events',
      element: <EventsHome />
    },
		{
      path: '/about',
      element: <AboutUs />
    },
    // {
    //   path: '/myCategories/*',
    //   element: <MyCategoryRouter />
    // },
    {
      path: '/myProfile',
      element: <Profile/>
    },
    {
      path: '/changepassword',
      element: <ChangePassword/>
    },
    {
      path:'/forgotPassword',
      element:<ForgotPassword/>
    }
  ]);
  return routes;
};

export default RootRouter;