import { Any, CommonSuccessAPIResponse } from '@vokab/shared/src/types';
import axios, { AxiosRequestConfig, AxiosResponse, Method } from 'axios';

export const instance = () => axios.create({
	baseURL: '/api/'
});

const setAuthToken = () => {
	const token = localStorage.getItem('authToken');
	if (!token)
		return {};
	return {
        headers: {
            'auth-token': token ? `Bearer ${token}` : '',
        },
    };
}

const request = (method:Method, url:string, data:Any,params?:any) => {
	let response = null;

	switch (method) {
	case 'PUT':
		response = instance().put(url, {...data, ...setAuthToken()});

		return response;

	case 'POST':
		response = instance().post(url, {...data, ...setAuthToken()});

		return response;

	case 'GET':
		response = instance().get(url, {...data, ...setAuthToken(),params});

		return response;

	case 'DELETE':
		response = instance().delete(url, {...data, ...setAuthToken()});

		return response;

	default:
		return response;
	}
};

export type ApiCallProps = {
	method: Method;
	url: string;
	data?: Any;
	/* By default, show Loading Spinner.  Use isNotWithLoading to call without loading spinner */
	isNotWithLoading?: boolean;
	params?:Any
};

export type CustomResponse<T> = [CommonSuccessAPIResponse<T> , Any ];

const asCustomResponse = <T>(promise: Any): CustomResponse<T> => promise.then(
	(res: AxiosResponse<CommonSuccessAPIResponse<T>>) => ([res.data, null])
).catch((e: Any) => ([null, e]));

// eslint-disable-next-line require-await
export const apiCall = async <T>(options: ApiCallProps) => {
	const { method, url, data,params } = options;
	return asCustomResponse<T>(request(method, url, data,params));
};

// eslint-disable-next-line require-await
export const apiCallWithLoading = async <T>(options: ApiCallProps) => {
	const { method, url, data } = options;
	return asCustomResponse<T>(request(method, url, data));
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const getApiErrorDataMessage = (error: Any): string => {
	if (typeof error === 'string')
		return error;
	if (axios.isAxiosError(error))
		return error.response?.data.message;
	if (typeof error === 'object' && error && Object.prototype.hasOwnProperty.call(error, 'message'))
		return error.message;
	return 'unknown error';
};