import { useEffect, useMemo } from "react";
import { useAppHook } from "../../shared/hooks/useAppHook";
import { Layout } from "../home/Layout"
import CategoryListTable from "./CategoryListTable"
import { VocabularyTable } from "./vocabularyTable";
import { useUserContextWrapper } from "../../shared/contexts/UserContextWrapper";

export const VocabularyHome = () => {
  const { getAllLanguages, getAllProfLevels } = useAppHook();
  const { user } = useUserContextWrapper();
  const userId = useMemo(() => user?.userId ?? '', [user])

  useEffect(() => {
    if (userId){

		}
     
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  useEffect(() => {
    Promise.all([
      getAllLanguages(),
      getAllProfLevels(),
    ]).then();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  return (
    <VocabularyTable>
      <div>
        <Layout>
          <div className="mt-3 mx-auto">
            <CategoryListTable />
          </div>
        </Layout>

      </div>
    </VocabularyTable>
  )
}