import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ICommonProps {
	selected_proficiency_level_id?: string;
	selected_category_id?: string;
	selected_lesson_id?: string;
}
export const initialState: ICommonProps = {}
const appSlice = createSlice({
	name: "common",
	initialState,
	reducers: {
		setSelectedProficiencyLevelId(state, action: PayloadAction<string>) {
			state.selected_proficiency_level_id = action.payload;
		},
		setSelectedCategoryId(state, action: PayloadAction<string>) {
			state.selected_category_id = action.payload;
		},
		setSelectedLessonId(state, action: PayloadAction<string>) {
			state.selected_lesson_id = action.payload;
		},
	},
});


export const { actions, reducer } =
	appSlice;
