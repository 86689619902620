import { IForgotDetailsPayload } from "@vokab/shared/src/types";
import { apiCallWithLoading } from "./";



export const sendForgotPassword = (payload: IForgotDetailsPayload) => apiCallWithLoading<string>({
	method: 'GET',
	url: `auth/password/reset/request?email=${payload.email}`,
	data: payload,

});