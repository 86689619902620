import { Link, useNavigate } from 'react-router-dom';
import { useUserContextWrapper } from '../../shared/contexts/UserContextWrapper';
import { Button, Dropdown, DropdownButton, Nav } from 'react-bootstrap';
import { useAutHook } from '../../shared/hooks/useAuthHook';



function MyAccountDropdown() {
	const { isLoggedIn } = useUserContextWrapper();
	const { logout } = useAutHook();
	const navigate = useNavigate();
	if (isLoggedIn)

		return (
			<Nav.Link >
				<DropdownButton variant='outline-light' title="my account"  style={{width:'100%'}} className='my-account'>
					<Dropdown.Item onClick={() => navigate('/myProfile')}>my profile</Dropdown.Item>
					<Dropdown.Item onClick={() => navigate('/ChangePassword')}>change password</Dropdown.Item>
					<Dropdown.Item onClick={() => logout()}>log out</Dropdown.Item>
				</DropdownButton>
			</Nav.Link>
		);

	return (
		<div style={{
			display: "flex",
			flexDirection: "row",
			justifyContent: "end",
			alignItems: "center",
			width: "100%",
		
		}}>
			
				<Nav.Link as={Link} to='/auth/login' >
					<Button variant="outline-light" >log in</Button>
				</Nav.Link>
			
			
				<Nav.Link as={Link} to='/auth/signup'>
					<Button variant="outline-light">sign up</Button>
				</Nav.Link>
			
		</div>
	)
}

export default MyAccountDropdown;