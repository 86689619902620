import 'bootstrap/dist/css/bootstrap.min.css';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';
import { ForgotPasswordStyle } from './style';
import { AppColorBackground } from '../../styled-components/common';

const ForgotPassword = () => {
  return (
    <ForgotPasswordStyle>
    <AppColorBackground>
    
    </AppColorBackground>
    </ForgotPasswordStyle>
  )
}

export default ForgotPassword;
