/* eslint-disable jsx-a11y/anchor-is-valid */
import { Container, Navbar, Nav,Image } from "react-bootstrap";
import { AppColorBackground } from "../../styled-components/common";
import styled from "styled-components";
import { FaBars } from "react-icons/fa";
import { Link } from "react-router-dom";
import SidebarPanel from "./SidebarPanel";
import { useState } from "react";
import MyAccountDropdown from "./MyAccountDropdown";

const CustomNavBar = styled(Navbar)`
  div.navbar-nav {
    a.nav-link {
      font-size: 1.4vw;
      font-weight: normal;
     
      
      }
		a.nav-link:hover {
        font-weight: bold;
      }
    a.nav-link > button, a.nav-link > .dropdown > button {
      color: var(--text-white);
      border-color: var(--text-white);
      font-size: 1.4vw;
      width:85%;

      
     
    }
   
 
    a.nav-link > button:hover, a.nav-link > .dropdown > button:hover {
      background-color: var(--text-white);
      color: var(--app-color1);
      font-weight: bold;
      
    }
 
    div > svg {
      font-size: 1.5vw;
    }
    .container-fluid{
      height:100px;
    }
  .large-btn .nav-link{
    width:80%;
    display:flex;
    justify-content:end;
    align-items:center;
    padding:0;
  }
`;

export default function Header() {
	const [showPanel, setShowPanel] = useState<boolean>(false);
	const pathName = window.location.pathname;
	const getStyle = (path: string) => {
		if (path === pathName)
			return {
				fontWeight: "bold",
			};
		return {};
	};

	return (
		<Container fluid className="px-0">
			{/* <CustomNavBar collapseOnSelect expand="lg" className="p-0">
				<Container
					fluid
					className="d-flex justify-content-between align-items-center nav-header p-0 flex-wrap bg-primary"
				>
					<div className="col-3">
						<Navbar.Brand
							href="/"
							className="ms-5 d-flex justify-content-start align-items-center "
						>
							<img
								src="/logo.png"
								style={{ width: "5.5rem" }}
								className="d-inline-block align-top p-0"
								alt="App logo"
							/>
						</Navbar.Brand>
					</div>

					<Navbar.Collapse id="basic-navbar-nav">
						<Nav className=" d-flex  col-11">
							<div className="d-flex justify-content-between align-items-center col-11">
								<div className="d-flex justify-content-between col-8 ">
									<Nav.Link style={getStyle("/")} as={Link} to={"/"}>
										home
									</Nav.Link>
									<Nav.Link
										style={getStyle("/vocabulary")}
										as={Link}
										to={"/vokab"}
									>
										vokab
									</Nav.Link>
									<Nav.Link
										as={Link}
										style={getStyle("/flashcards")}
										to={"/flashcards"}
									>
										course
									</Nav.Link>
									<Nav.Link
										as={Link}
										style={getStyle("/events")}
										to={"/events"}
									>
										events
									</Nav.Link>
									<Nav.Link as={Link} style={getStyle("/about")} to={"/about"}>
										about us
									</Nav.Link>
								</div>
								<div className="d-flex justify-content-center  col-4 large-btn">
									<MyAccountDropdown />
								</div>
								<div className="d-flex justify-content-center align-items-center col-1 pointer">
									<FaBars
										className="text-white"
										onClick={() => setShowPanel(true)}
									/>
								</div>
							</div>
						</Nav>
					</Navbar.Collapse>
					<Navbar.Toggle />
				</Container>
			</CustomNavBar> */}
			{/* <nav className="navbar navbar-expand-lg bg-primary rounded" aria-label="Thirteenth navbar example">
      <div className="container-fluid">
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarsExample11" aria-controls="navbarsExample11" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>

        <div className="collapse navbar-collapse d-lg-flex" id="navbarsExample11">
          <a className="navbar-brand col-lg-3 me-0" href="#">Centered nav</a>
          <ul className="navbar-nav col-lg-6 justify-content-lg-center">
            <li className="nav-item">
              <a className="nav-link active" aria-current="page" href="#">Home</a>
            </li>
            <li className="nav-item">
              <a className="nav-link" href="#">Link</a>
            </li>
            <li className="nav-item">
              <a className="nav-link disabled" aria-disabled="true">Disabled</a>
            </li>
            <li className="nav-item dropdown">
              <a className="nav-link dropdown-toggle" href="#" data-bs-toggle="dropdown" aria-expanded="false">Dropdown</a>
              <ul className="dropdown-menu">
                <li><a className="dropdown-item" href="#">Action</a></li>
                <li><a className="dropdown-item" href="#">Another action</a></li>
                <li><a className="dropdown-item" href="#">Something else here</a></li>
              </ul>
            </li>
          </ul>
          <div className="d-lg-flex col-lg-3 justify-content-lg-end">
            <button className="btn btn-primary">Button</button>
          </div>
        </div>
      </div>
    </nav> */}
			

			<Navbar expand="lg" className="bg-primary">
				<Navbar.Brand
						href="/"
					>
						<Image


							src="/logo.png"
							style={{ width: "5rem" }}
							className="img-fluid"
							alt="App logo"
						/>
					</Navbar.Brand>
					<Navbar.Toggle className="text-white"  aria-controls="basic-navbar-nav" >
					<span className="navbar-toggler-icon "></span>
					</Navbar.Toggle>
					<Navbar.Collapse className="d-lg-flex justify-content-center" id="basic-navbar-nav">
						<Nav className="justify-content-lg-center	">
							<Nav.Link style={getStyle('/')} className="text-end text-sm-start" href="/">home</Nav.Link>
							<Nav.Link style={getStyle('/vokab')}   className="text-end text-sm-start" href="/vokab">vokab</Nav.Link>
							<Nav.Link style={getStyle('/flashcards')}   className="text-end text-sm-start" href="/flashcards">courses</Nav.Link>
							<Nav.Link style={getStyle('/events')}   className="text-end text-sm-start" href="/events">events</Nav.Link>
							<Nav.Link style={getStyle('/about')}    className="text-end text-sm-start" href="/about">about us</Nav.Link>
						</Nav>
						<Nav>
							<MyAccountDropdown />
						</Nav>
					</Navbar.Collapse>
			</Navbar>
			</Container>
	);
}
