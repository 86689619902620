import { Container, Tab, Tabs } from "react-bootstrap";
import { AuthPageLogo, AuthenticationFormContainer, AppColorBackground } from "../../styled-components/common";
import Notify from "../../shared/components/Notify";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { FormStyle } from "./style";

const AuthTabEnum = {
	LOGIN: '/auth/login',
	SIGNUP: '/auth/signup'
};

export default function AuthenticationPage() {
	const navigate = useNavigate();
	const location = useLocation();
	const onClick = () => {
		navigate('/')
	}
	return (
		<AppColorBackground className="min-vh-100 bg-primary">
			<FormStyle>
			<Notify />
			<Container>
				<div className="logo col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-xs-12 text-center pointer">
					<AuthPageLogo onClick={onClick} src="/logo.png"  alt="image-invalid" className="logo-default mx-auto" />
				</div>
				<AuthenticationFormContainer className="container col-xxl-4 col-xl-5 col-lg-4 col-md-8 col-sm-9 col-xs-9 rounded-5 p-0">
					<Tabs
						activeKey={location.pathname}
						onSelect={(k) => navigate(k as string)}
						justify
					>
						<Tab eventKey={AuthTabEnum.LOGIN} className="form-header" title="log in"/>
						<Tab eventKey={AuthTabEnum.SIGNUP} className="form-header" title="sign up" />
					</Tabs>
					<Tab.Content>
						<Outlet />
					</Tab.Content>
				</AuthenticationFormContainer>
			</Container>
			</FormStyle>
		</AppColorBackground>
	)
}