import * as Yup from 'yup';
import {
	checkEmail,
	checkPassword,
	checkStringRequiredLength,
	checkConfirmPassword
} from '../validation/validators';

export const userLogin = Yup.object({
	email: checkEmail(),
	password: checkPassword()
});

export const userRegistration = Yup.object({
	email: checkEmail(),
	firstName: checkStringRequiredLength(),
	lastName: checkStringRequiredLength(),
	password: checkPassword(),
	confirmPassword: checkConfirmPassword()
});

export const resetPasswordSchema = Yup.object({
	password: checkPassword(),
	confirmPassword: checkConfirmPassword()
});