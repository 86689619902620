import { DetailedHTMLProps } from "react";




export default function VokabLinkButton(props:DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>) {
	return (
		<div {...props}>
			<button type="button" className="btn my-4 px-4 py-3 text-black  fst-underline " style={{ minWidth: '200px', borderRadius: '30px', borderWidth: '4px', borderColor: 'rgb(75, 77, 119)', backgroundColor: 'rgb(207, 208, 225)' }}>
			<a href="http://vokab.org" target="_blank" rel="noopener noreferrer" className="h4 fw-bolder text-decoration-none">go to <span className="text-decoration-underline">vokab.org</span></a>
		</button>
		</div>
	);
}
