import { Col, Row } from "react-bootstrap";
import styled from "styled-components";
import { DashedLine } from "../../styled-components/common";
import { Layout } from "./Layout";
const StyledParagraph = styled.p`
	color: var(--app-color1);
`;
export const HomeStyle = styled.div`
	home-font {
		// font-size: 1.6vw;
		// color: #2A799B;
		text-align: justify;
	}
	.link {
		font-size: 1.45vw !important;
		font-style: italic;
	}
	a {
		color: #7c7ca1;
	}

	.img-width {
		width: 25vw;
		height: 25vw;
		border-radius: 20%;
	}
	.IoChevronWidth {
		font-size: 4vw;
	}
	h1 {
		font-size: 3vw !important;
		color: --var(app-color1);
		font-weight: 900;
	}
	@media (max-width: 549px) {
		.home-font {
			font-size: 4.5vw;
		}
		.link {
			font-size: 4vw !important;
		}
		.mt-5 {
			margin: 0 !important;
		}
		.img-width {
			width: 55vw;
			height: 55vw;
		}
		.margin-top_l {
			margin-top: 4vw;
		}
		h1 {
			font-size: 6vw !important;
		}
	}
	@media (max-width: 767px) and (min-width: 550px) {
		.home-font {
			font-size: 3.5vw;
		}
		.link {
			font-size: 3.5vw !important;
		}
		.mt-5 {
			margin: 0 !important;
		}
		.img-width {
			width: 40vw;
			height: 40vw;
		}
		.margin-top_l {
			margin-top: 3vw;
		}
		h1 {
			font-size: 4.5vw !important;
		}
	}

	@media screen and (max-width: 991px) and (min-width: 768px) {
		.home-font {
			font-size: 2.8vw;
		}
		.link {
			font-size: 3vw !important;
		}
		.mt-5 {
			margin: 0 !important;
		}
		.img-width {
			width: 35vw;
			height: 35vw;
		}
		.margin-top_l {
			margin-top: 2vw;
		}
		h1 {
			font-size: 4vw !important;
		}
		.display-none {
			display: none;
		}
	}

	@media (max-width: 1200px) and (min-width: 992px) {
		.home-font {
			font-size: 2vw;
		}
		.link {
			font-size: 2.5vw !important;
		}
		.mt-5 {
			margin: 0 !important;
		}
		.mt-4 {
			margin-top: 0.5rem !important;
		}
		.img-width {
			width: 30vw;
			height: 30vw;
		}
	}
`;

export default function Home() {
	return (
		<Layout>
			<div
				className="mt-4 container-fluid col-12 d-flex justify-content-center align-items-center"
				id="vocabulary"
			>
				<HomeStyle>
					<Row
						className="d-flex align-items-center"
						style={{ height: "calc(100vh - 130px)" }}
					>
						<Col lg={6} xs={12} className="order-last">
							<div className="container-fluid margin-top_l p-0 col- cmn-font">
								<Col>
									<p className="mb-0">welcome to</p>
									<p className="h1 mb-0">
										<span className="h1 tm-text fw-bolder ">பேச்சு . </span>
										<span className="fw-bold">pechu</span>
									</p>
									<p className="fst-italic">learn to speak confidently</p>
									<DashedLine className="col-lg-11" />
								</Col>
								<p className="h1 fw-bold">
									pechu{" "}
									<span
										style={{ fontFamily: "Noto Sans, sans-serif" }}
										className="h3"
									>
										/peːt͡ɕːɯ/
									</span>
								</p>
								<p className="fst-italic">noun</p>
								<p>
									<b className="me-2">1.</b>
									an initiative created to boost{" "}
									<b>day-to-day speaking skills</b> for the <b>language</b>{" "}
									learners of South India.
								</p>

								<p>
									<b className="me-2">2.</b>
									speech
								</p>
								<p>
									<StyledParagraph className="link mt-5 anchor d-inline">
										for more initiatives, visit{" "}
										<a
											href="https://linguistics.world"
											target="_blank"
											rel="noreferrer"
										>
											www.linguistics.world
										</a>
									</StyledParagraph>
								</p>
							</div>
						</Col>

						<Col lg={6} xs={12} className="order-first">
							<div className="d-flex align-items-center justify-content-center">
								{/* <div className="me-4">
									<button className="border-0" style={nextButtonStyle}>
										<IoChevronBackCircleOutline className="IoChevronWidth text-primary" />
									</button>
								</div> */}

								<img
									style={{
										borderRadius: "20%",
										height: "auto",
										width: "60%",
									}}
									src="/logo_with_bg.png"
									alt=""
									className="img-fluid"
								/>

								{/* <div className="ms-4">
									<button className="border-0" style={nextButtonStyle}>
										<IoChevronForwardCircleOutline className="IoChevronWidth text-primary" />
									</button>
								</div> */}
							</div>
						</Col>
					</Row>
				</HomeStyle>
			</div>
		</Layout>
	);
}
