import { Container } from "react-bootstrap";
import Header from "./Header";

interface IProps {
  children: React.ReactNode;
}

export const Layout = ({ children }: IProps) => {
  return (
    <Container fluid id='vokab' className="p-0 min-vh-100">
      <Header />
      <Container fluid style={{
        overflowY:'auto',
        overflowX:'hidden',
        minHeight:'calc(100vh - 100px)',
        maxWidth:'100vw'
   
      }}>
	
        {
          children
        }
      </Container >
    </Container>
  )
}